import React from 'react';
import { Link } from 'react-router-dom';


const handleLogout = async () => {
  try {
    const response = await fetch('https://travelworth.ca/api/users/logout', {
      method: 'POST',
      credentials: 'include' // Important for session cookies
    });
    
    const data = await response.json();
    
    if (response.ok) {
      // Redirect to login page
      window.location.href = data.redirectTo || '/login';
    } else {
      // Handle logout error
      console.error('Logout failed');
    }
  } catch (error) {
    console.error('Logout error:', error);
  }
};


const Sidebar = () => {
  return (
    <div className="w-64 h-auto bg-gray-800 text-white flex flex-col ">
      <h2 className="text-3xl p-4">Admin Panel</h2>
      <ul className="flex flex-col  h-screen">
      <li className="p-4 hover:bg-gray-700">
          <Link to="/customerfeedback">Customer Request</Link>
        </li>

        <li className="p-4 hover:bg-gray-700">
          <Link to="/index-two">Tour Packages</Link>
        </li>
       

        <li className="p-4 hover:bg-gray-700">
          <Link to="/managedestinations">Manage Destinations</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/manageplaces">Manage Places</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/food">Manage Food</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/manageculture">Manage Culture</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/managevisit">Manage MustVisitPlace</Link>
        </li>
        <li className="p-4 hover:bg-gray-700">
          <Link to="/dolist">Manage Things to do</Link>
        </li>
        
    
    <button 
      onClick={handleLogout} 
      className="logout-btn"
    >
      Logout
    </button>

      </ul>
    </div>
  );
};

export default Sidebar;
